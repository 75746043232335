@media (max-width: 768px) {
    #slider, .breadcrumbs {
        margin-top: unset;
    }
    #header {
        all: unset;
    }
    .breadcrumbs {
        padding: 32px 0;
    }
    .breadcrumbs h1 {
        margin-top: 20px;
        font: normal normal bold 24px/0px Poppins;
    }
    .breadcrumbs ul li a, .breadcrumbs ul li+li, .breadcrumbs ul li+li::before {
        font: normal normal 500 16px/0px Poppins;
    }
    .breadcrumbs ul li+li::before {
        margin-top: 15px;
    }
    #slider .content h1 {
        font-size: 18px;
        line-height: 25px;
    }
    #slider .content {
        transform: translate(-44%, -43%);
    }
    #slider .content p {
        font-size: 14px;
    }
    #slider .slick-dots li button {
        height: 5px;
    }
    section {
        padding-top: 40px;
        padding-bottom: 35px;
    }
    h2 {
        margin-bottom: 38px;
        font-size: 26px;
        line-height: 38px;
    }
    p {
        font-size: 14px;
        line-height: 30px;
    }
    .service {
        margin: 10px;
    }
    #pricing button.MuiButtonBase-root.Mui-selected {
        font-size: 15px;
        font-weight: 400;
    }
    #pricing .MuiBox-root {
        margin-top: unset;
    }
    .pricing {
        margin-top: 3rem;
    }
    .benefits h3 {
        font-size: 13px;
    }
    #solar .video .img-main {
        margin-bottom: unset;
    }
    #solar .overlay {
        top: 36%;
    }
    #solar h2 {
        margin-top: 1rem;
        font-size: 26px;
        line-height: 38px;
    }
    .cmt-1 {
        margin-top: unset;
    }
    .client {
        margin-top: 1rem;
    }
    .copyright p {
        text-align: center;
    }
    .bg-2 img {
        width: 50%;
        padding-bottom: none;
    }
    #revolution .content {
        margin: 30px;
        margin-top: -2rem;
    }
    .benefit {
        margin-top: unset;
    }
    .work {
        margin-left: 10px;
        margin-right: 10px;
    }
    #pricing button.MuiButtonBase-root {
        font-size: 15px;
        font-weight: 400;
    }
    #schedule h2 {
        font-size: 23px;
        font-weight: 700;
        margin-bottom: 12px;
    }
    .btn-pricing {
        font-size: 13px;
        padding-left: 13px;
        padding-right: 13px;
    }
    section#schedule {
        padding-top: 28px;
        margin-bottom: 46px;
    }
    #schedule .call img {
        width: 15%;
    }
    #schedule .call h3 {
        font-size: 14px;
        font-weight: 700;
    }
    #schedule .call h3 span {
        font-size: 10px;
    }
    .contact-info {
        margin: 15px;
    }
    .form {
        padding: 26px;
    }
    .form .form-control, .form button {
        margin-top: 24px;
    }
}