body {
    font-family: 'Poppins', sans-serif;
}

a {
    text-decoration: none;
}

h2 {
    margin-bottom: 45px;
    font-size: 30px;
    line-height: 46px;
    font-weight: 500;
    color: #000000;
    position: relative;
    opacity: .8;
}

h2.type-2 {
    color: #FFFFFF;
    opacity: 1;
}

p {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    color: #000000;
    opacity: 0.7;
}

h2 span {
    font-weight: 600;
}

h2::after {
    display: block;
    position: absolute;
    width: 5.625rem;
    height: .5rem;
    content: '';
    background: linear-gradient(to right, #56A3F8 0, #56A3F8 10%, #136CCF 100%) !important;
    border-radius: 10px;
    border: 2px solid #56A3F8;
    left: 50%;
    margin-top: 10px;
    margin-left: -45px;
}

h2.type-2::after {
    background: linear-gradient(to right, #fff 0, #fff 10%, #FFFFFF 100%) !important;
    border: 2px solid #FFFFFF;
}

h2.type-3::after {
    left: unset;
    margin-top: 10px;
    margin-left: unset;
}

.menuicon {
    color: #1264B1;
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    transform: scaleX(-1);
}

.section-bg {
    background-color: #F9F9FD;
}

.btn-pricing {
    font-size: 16px;
    color: #000000;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 29px;
}

.btn-pricing:hover {
    background-color: transparent;
    border-color: #fff;
}

.btn-pricing svg {
    font-size: inherit;
    color: #1966BB;
}

.btn-pricing:hover svg {
    color: #fff;
}

/* Header */

#header {
    position: relative;
    z-index: 9999999;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

.MuiTypography-root {
    color: #000;
}

#header .container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

/* Navbar */

#navbar ul {
    justify-content: flex-end;
}

.navbar ul {
    margin-top: 20px;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li {
    padding: 10px 0 10px 40px;
}

.navbar ul li:first-child {
    padding-left: 0;
}

.navbar ul li a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.btn-2 {
    margin: 5px;
    width: 200px;
    border-radius: 55px;
    transition: all 0.5s;
    padding: 8px 13px 8px 13px;
    color: #FFFFFF !important;
}

.btn-2 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.btn-2 span:after {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 5px;
    transition: 0.5s;
}

.btn-2:hover span {
    padding-left: 100px;
}

.btn-2:hover span:after {
    opacity: 1;
    right: 0;
}

.btn-quote {
    background-color: #136CCF;
    font-size: 14px !important;
}

.btn-service {
    background-color: #42BD5C;
}

.btn-service span:after {
    content: 'Know More';
}

.navbar ul li.active a {
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 2px solid #FDCB00;
}

/* END: Navbar */

#slider .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#slider .content h1 {
    font-size: 50px;
    line-height: 70px;
    font-weight: 500;
    color: #FFFFFF;
}

#slider .content h1 span.type-1 {
    font-weight: 600;
    color: #FFCB00;
}

#slider .content h1 span.type-2 {
    font-weight: 600;
    color: #03D54B;
}

#slider .content p {
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    color: #FFFFFF;
    opacity: 1;
}

#slider .slider-1 {
    position: relative;
}

#slider .slider-1 .centered {
    background: rgba(3, 24, 46, .52) 0 0 no-repeat padding-box;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    /* transform: translate(-22%, -50%); */
}

#slider .slick-dots li.slick-active button:before, .slick-dots li button:before {
    all: unset;
}

#slider .slick-dots li {
    width: unset;
    height: unset;
}

#slider .slick-dots li button {
    background-color: #42BD5C;
    height: 7px;
    padding: unset;
    width: 8px;
    border-radius: 10px;
    transition: width 0.5s;
}

#slider .slick-dots li.slick-active button {
    width: 28px;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

section#schedule {
    padding-top: 42px;
    padding-bottom: 42px;
    margin-bottom: 80px;
}

.breadcrumbs {
    text-align: center;
    padding: 96px 0;
    min-height: 100%;
    background: linear-gradient(111deg, rgb(19 108 207 / 40%) 60%, rgb(19 108 207 / 40%) 60%, rgb(19 108 207 / 40%) 60%), url(../images/bg-breadcumb.png);
    background-size: cover;
}

.breadcrumbs h1 {
    font: normal normal 600 33px/40px Poppins;
    color: #FFFFFF;
}

.breadcrumbs ul {
    padding-left: unset;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.breadcrumbs ul li a {
    text-decoration: none;
    font: normal normal 500 20px/40px Poppins;
    color: #FFFFFF;
}

.breadcrumbs ul li a:hover {
    color: #03abee !important;
}

.breadcrumbs ul li+li {
    padding-left: 10px;
    font: normal normal 500 20px/40px Poppins;
    color: #FFFFFF;
}

.breadcrumbs ul li+li::before {
    display: inline-block;
    padding-right: 10px;
    content: "/";
    font: normal normal 500 20px/40px Poppins;
    color: #FFFFFF;
}

.service {
    margin: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #46679529;
}

.service .content {
    padding: 30px 25px 30px 25px;
}

.service h3 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.8;
}

.service p {
    font-size: 13px;
    line-height: 28px;
    font-weight: 400;
    opacity: 0.7;
}

#pricing .MuiBox-root {
    margin-top: 3rem;
}

#pricing p.MuiTypography-root {
    opacity: unset;
}

#pricing .MuiTabs-indicator {
    all: unset;
}

#pricing button.MuiButtonBase-root {
    font-size: 18px;
    font-weight: 600;
    border-radius: 34px;
    color: #fff;
    padding-right: 28px;
    padding-left: 28px;
    text-transform: capitalize;
}

#pricing button.MuiButtonBase-root {
    font-size: 16px;
    font-weight: 500;
}

#pricing button.MuiButtonBase-root.Mui-selected {
    color: #000000;
    background-color: #FFF;
    border-radius: 34px;
}

#pricing {
    background: linear-gradient(111deg, rgb(1 49 103/60%) 60%, rgb(1 49 103/60%) 0, rgb(1 49 103/60%) 0), url(../images/bg-1.png);
    background-size: cover;
}

.pricing {
    background-color: #FFFFFF;
    box-shadow: 0px 10px 30px #46679529;
    border-radius: 9px;
}

.pricing .header {
    text-align: center;
}

.pricing .header h4 {
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.pricing .header.type-1 {
    padding: 20px;
    background-color: #FFF2BF;
    border-radius: 9px;
}

.pricing .header.type-1 h4 {
    font-size: 26px;
}

.pricing .header.type-1 hr {
    height: 3px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFCB00;
}

.pricing .header.type-2 {
    padding: 30px;
    background-color: #D0EED6;
    border-radius: 9px;
}

.pricing .header.type-2 h4 {
    font-size: 32px;
}

.pricing .header.type-2 hr {
    height: 3px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #42BD5C;
}

.pricing .body {
    padding: 20px;
}

.pricing .body ul {
    list-style: none;
    padding-left: unset;
}

.pricing .body ul li {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    line-height: 34px;
    color: #000000;
    /* opacity: 0.8; */
}

.pricing .body ul li svg {
    margin-right: 10px;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 3px;
    background-color: #42BD5C;
    font-size: 20px;
}

.pricing .footer {
    text-align: center;
}

.pricing .footer svg {
    color: #FFFFFF;
    border-radius: 50%;
    padding: 14px;
    background-color: #136CCF;
    font-size: 53px;
    margin-bottom: -25px;
}

.benefits h3 {
    margin-top: 1rem;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    opacity: 0.8;
}

.benefits {
    text-align: center;
    margin-top: 3rem;
}

#whyus {
    margin-top: 2rem;
}

.whyus {
    position: relative;
    margin-top: -3rem;
}

#whyus .content {
    padding-top: 60px;
    padding-bottom: 60px
}

.whyus-2 {
    margin-top: -7rem;
    margin-left: -2rem;
}

#solar {
    padding-top: 50px;
    padding-bottom: 28px;
    background: linear-gradient(111deg, rgb(54 199 77 / 91%) 60%, rgb(54 199 77 / 91%) 60%, rgb(54 199 77 / 91%) 60%), url(../images/bg-breadcumb.png);
    background-size: cover;
}

#solar .overlay {
    position: absolute;
    left: 50%;
    margin-left: -45px;
    top: 50%;
    z-index: 999;
}

#solar h2 {
    font-size: 36px;
    line-height: 60px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px;
    opacity: 1;
}

#solar h2::after {
    all: unset;
}

#solar .video {
    position: relative;
}

#solar .video .img-main {
    margin-bottom: -7rem;
}

.cmt-1 {
    margin-top: 5rem;
}

.work {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    text-align: center;
    background-color: #136CCF;
    padding: 20px;
}

.work .number {
    padding-top: 10px;
    text-align: left;
    font-size: 64px;
    font-weight: 600;
    opacity: 0.19;
    color: #FFFFFF;
}

.work h3 {
    margin-top: 1rem;
    font-size: 17px;
    font-weight: 600;
    color: #FFFFFF;
}

.work p {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    opacity: 0.99;
    color: #FFFFFF;
}

#schedule {
    background-color: #1966BB;
}

#schedule h2 {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 24px;
    color: #FFFFFF;
    opacity: 1;
}

#schedule h2::after {
    all: unset;
}

#schedule .call img {
    margin-left: 1rem;
    margin-right: 1rem;
}

#schedule .call h3 {
    font-size: 18px;
    font-weight: 800;
    color: #FFFFFF;
    opacity: 1;
}

#schedule .call h3 span {
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.9;
}

.client {
    padding: 25px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #597FBC29;
}

#revolution .row>* {
    padding-left: unset;
    padding-right: unset;
}

#revolution .content {
    margin-top: -11rem;
    margin-left: 20rem;
    padding: 20px;
    background-color: #136CCF;
}

#revolution .content p {
    font-size: 14px;
    line-height: 30px;
    color: #FFF;
    opacity: 1;
}

.benefit {
    margin-top: 2rem;
    padding: 20px;
    transition: all 1s;
}

.benefit:hover img {
    filter: brightness(0) invert(1);
}

.benefit:hover {
    background-color: #136CCF;
}

.benefit h3 {
    margin-top: 1rem;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.benefit:hover h3 {
    color: #fff;
    opacity: 1;
}

.benefit p {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #000000;
    opacity: 0.7;
}

.benefit:hover p {
    color: #fff;
    opacity: 1;
}

.bg-2 img {
    padding: 20px;
    padding-bottom: 50px;
    background-color: #43CB45;
}

.form {
    padding: 45px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 30px #53749A29;
}

.form .MuiInputLabel-shrink {
    padding-left: 7px !important;
    padding-right: 7px !important;
    background-color: #FFF !important;
    color: #136CCF !important;
}

.form .form-control {
    margin-top: 35px;
}

.form .react-tel-input {
    margin-top: 30px;
}

.form .react-tel-input .form-control {
    height: 44px;
    width: 100%;
    margin-top: 30px;
}

#callback .react-tel-input .form-control {
    width: 100% !important;
}

.form .form-control:focus {
    border: 2px solid #136CCF;
    box-shadow: unset;
}

.form-slider .form-control, .form-slider button {
    margin-top: 25px;
}

.form .MuiFormLabel-root {
    top: -3px !important;
}

.form button, .form-slider button {
    border: none;
    padding: 10px 20px 10px 20px !important;
    background-color: #1264B1;
    border-radius: 6px;
    color: #FFFFFF !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 1;
}

.form-slider {
    padding: 30px;
    background-color: #FFFFFF96;
}

.form-slider h3 {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #136CCF !important;
}

.contact-info {
    background-color: #136CCF;
    text-align: center;
}

.contact-info svg {
    margin: 0 10px 12px 10px;
    color: #fff;
}

.contact-info h4 {
    font: normal normal 600 18px/25px Poppins;
    color: #fff;
    opacity: 1;
}

.contact-info .icon {
    margin-top: 40px;
}

.contact-info p, .contact-info p a {
    font: normal normal normal 14px/21px Poppins;
    color: #fff;
    opacity: 1;
}

.contact-info .icon:before, .contact-info .icon:after {
    display: inline-block;
    content: "";
    border-top: 1px solid #fff;
    width: 48px;
    transform: translateY(-0.6rem);
}

.policy h2 {
    margin-top: 2rem;
    margin-bottom: unset;
}

.policy h2::after {
    all: unset;
}

#footer {
    background-image: url(../images/bg-footer.svg);
    background-position: top center;
    background-size: cover;
}

#footer section {
    padding-bottom: unset;
}

#footer hr {
    height: 2px;
    opacity: .5;
    color: #FFFFFF;
}

#footer .container {
    margin-top: 60px;
}

#footer p {
    font-size: 12px;
    line-height: 26px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
}

#footer h4 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #FFCB00;
    text-transform: uppercase;
    margin-bottom: 25px;
}

#footer h4:after {
    display: inline-block;
    content: "";
    border-bottom: 2px solid #FFCB00;
    width: 22px;
    margin-left: 6px;
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
}

#footer ul {
    list-style: none;
    padding-left: unset;
}

#footer ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

#footer ul li a {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #FFFFFF;
}

#footer .contact svg {
    float: left;
    color: #fff;
    font-size: 22px;
}

#footer .contact p, #footer .contact a {
    color: #fff;
}

#footer .contact p {
    padding-left: 30px;
}

#footer .social-media a {
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    margin-right: 10px;
}

.copyright p, .copyright a {
    color: #FFFFFF;
}

.copyright .links {
    text-align: right;
}